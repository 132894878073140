import tailwindConfig from "../tailwind.config.js";
import { definePreset } from "@primevue/themes";
import Lara from "@primevue/themes/lara";
import { warningsPrimeVueConfig } from "@vueda/theme/warnings.js";
import resolveConfig from "tailwindcss/resolveConfig.js";

const fullConfig = resolveConfig(tailwindConfig);
const scale = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "950"];
const tailwindColorScale = (colorName, extra) => {
    const result = {
        ...extra,
    };
    if (!(colorName in fullConfig.theme.colors)) {
        throw new Error(`Color ${colorName} not found in tailwind config`);
    }
    for (const s of scale) {
        result[s] = fullConfig.theme.colors[colorName][s];
    }
    return result;
};

const CustomizedLara = definePreset(Lara, {
    primitive: {
        blue: tailwindColorScale("azure-radiance"),
        green: tailwindColorScale("christi"),
        red: tailwindColorScale("maroon"),
        purple: tailwindColorScale("fuchsia-blue"),
        yellow: tailwindColorScale("amber"), // message warn...
        amber: tailwindColorScale("amber"),
        orange: tailwindColorScale("amber"), // button warn...
    },
    semantic: {
        primary: tailwindColorScale("fuchsia-blue"),
        colorScheme: {
            light: {
                surface: tailwindColorScale("stone"),
            },
            dark: {
                surface: tailwindColorScale("stone"),
            },
        },
        extend: {
            colorScheme: {
                light: {
                    ...warningsPrimeVueConfig.semantic.extend.colorScheme.light,
                },
                dark: {
                    ...warningsPrimeVueConfig.semantic.extend.colorScheme.dark,
                },
            },
        },
    },
    components: {
        ...warningsPrimeVueConfig.components,
        divider: {
            content: {
                background: "transparent",
            },
            border: {
                color: "{green.200}",
                width: "2px",
                minWidth: "1.5rem",
            },
            css: ({ dt }) => `
.p-divider-horizontal {
    display: flex;
    align-items: baseline;
    position: relative;
    margin: ${dt("divider.horizontal.margin")};
    padding: ${dt("divider.horizontal.padding")};
}
.p-divider-left.p-divider-horizontal:before,
.p-divider-right.p-divider-horizontal:after {
    flex-grow: 0;
    min-width: ${dt("divider.border.minWidth")};
}
.p-divider-horizontal:before,.p-divider-horizontal:after {
    border-block-start: ${dt("divider.border.width")} solid ${dt("divider.border.color")};
    content: '';
    flex-grow:1;
}
.p-divider-horizontal::before {
    position: static;
    margin-inline-end: ${dt("divider.gap")};
    width: auto;
}
.p-divider-horizontal::after {
    margin-inline-start: ${dt("divider.gap")};
}
.p-divider-content {
    background-color: ${dt("divider.content.background")};
    z-index: 1; /* Ensure content is above divider lines */
}
`,
        },
        paginator: {
            // one less place to override
            background: "transparent",
        },
        button: {
            colorScheme: {
                // make light mode secondary a bit more saturated
                light: {
                    root: {
                        secondary: {
                            background: "{surface.200}",
                            hoverBackground: "{surface.300}",
                            activeBackground: "{surface.400}",
                            borderColor: "{surface.200}",
                            hoverBorderColor: "{surface.300}",
                            activeBorderColor: "{surface.400}",
                            color: "{surface.700}",
                            hoverColor: "{surface.800}",
                            activeColor: "{surface.900}",
                            focusRing: {
                                color: "transparent",
                                shadow: "0 0 0 0.2rem {surface.300}",
                            },
                        },
                    },
                    outlined: {
                        secondary: {
                            hoverBackground: "{surface.200}",
                            activeBackground: "{surface.300}",
                            borderColor: "{surface.400}",
                            color: "{surface.800}",
                        },
                    },
                    text: {
                        secondary: {
                            hoverBackground: "{surface.200}",
                            activeBackground: "{surface.300}",
                            color: "{surface.800}",
                        },
                    },
                },
                // make dark mode secondary a bit more saturated
                dark: {
                    root: {
                        secondary: {
                            background: "{surface.700}",
                            hoverBackground: "{surface.600}",
                            activeBackground: "{surface.500}",
                            borderColor: "{surface.700}",
                            hoverBorderColor: "{surface.600}",
                            activeBorderColor: "{surface.500}",
                            color: "{surface.300}",
                            hoverColor: "{surface.200}",
                            activeColor: "{surface.100}",
                            focusRing: {
                                color: "transparent",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {surface.300}, transparent 80%)",
                            },
                        },
                    },
                    outlined: {
                        secondary: {
                            hoverBackground: "{surface.800}",
                            activeBackground: "{surface.700}",
                            borderColor: "{surface.600}",
                            color: "{surface.300}",
                        },
                    },
                    text: {
                        secondary: {
                            hoverBackground: "{surface.800}",
                            activeBackground: "{surface.700}",
                            color: "{surface.300}",
                        },
                    },
                },
            },
        },
        toggleswitch: {
            root: {
                width: "2rem",
                height: "1.125rem",
                borderRadius: "15px",
                gap: "0.25rem",
            },
            handle: {
                size: "0.75rem",
            },
        },
        toast: {
            colorScheme: {
                light: {
                    root: {
                        // add primary toasts.
                        primary: {
                            background: "{purple.50}",
                            borderColor: "{purple.500}",
                            color: "{purple.700}",
                            detailColor: "{purple.700}",
                            shadow: "{overlay.popover.shadow}",
                            closeButton: {
                                hoverBackground: "{purple.100}",
                                focusRing: {
                                    color: "{focus.ring.color}",
                                    shadow: "0 0 0 0.2rem {purple.200}",
                                },
                            },
                        },
                        // make toast backgrounds not transparent
                        // secondary was already not transparent
                        info: {
                            background: "{blue.50}",
                            color: "{blue.700}",
                        },
                        success: {
                            background: "{green.50}",
                            color: "{green.700}",
                        },
                        warn: {
                            background: "{yellow.50}",
                            color: "{yellow.700}",
                        },
                        error: {
                            background: "{red.50}",
                            color: "{red.700}",
                        },
                    },
                },
                dark: {
                    // add primary toasts
                    primary: {
                        background: "{purple.800}",
                        borderColor: "{purple.700}",
                        color: "{purple.100}",
                        detailColor: "{stone.0}",
                        shadow: "{overlay.popover.shadow}",
                        closeButton: {
                            hoverBackground: "{purple.700}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {purple.300}, transparent 80%)",
                            },
                        },
                    },
                    // higher contrast text
                    // no transparent backgrounds
                    secondary: {
                        // secondary was already not transparent
                        color: "{surface.100}",
                    },
                    info: {
                        background: "{blue.800}",
                        borderColor: "{blue.700}",
                        color: "{blue.100}",
                        closeButton: {
                            hoverBackground: "{blue.700}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {blue.300}, transparent 80%)",
                            },
                        },
                    },
                    success: {
                        background: "{green.800}",
                        borderColor: "{green.700}",
                        color: "{green.100}",
                        closeButton: {
                            hoverBackground: "{green.700}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {green.300}, transparent 80%)",
                            },
                        },
                    },
                    warn: {
                        background: "{yellow.800}",
                        borderColor: "{yellow.700}",
                        color: "{yellow.100}",
                        closeButton: {
                            hoverBackground: "{yellow.700}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {yellow.300}, transparent 80%)",
                            },
                        },
                    },
                    error: {
                        background: "{red.800}",
                        borderColor: "{red.700}",
                        color: "{red.100}",
                        closeButton: {
                            hoverBackground: "{red.700}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {red.300}, transparent 80%)",
                            },
                        },
                    },
                    contrast: {
                        // match light mode border color
                        borderColor: "{primary.color}",
                    },
                },
            },
            css: ({ dt }) => `
.p-toast-message-primary {
    background: ${dt("toast.primary.background")};
    border-color: ${dt("toast.primary.borderColor")};
    color: ${dt("toast.primary.color")};
    box-shadow: ${dt("toast.primary.shadow")};
    border-width: ${dt("toast.border.width")};
    border-style: solid;
    backdrop-filter: blur(${dt("toast.blur")});
    border-radius: ${dt("toast.border.radius")};
}
`,
        },
        message: {
            colorScheme: {
                light: {
                    // add primary messages
                    primary: {
                        background: "color-mix(in srgb, {purple.200}, transparent 5%)",
                        borderColor: "transparent",
                        color: "{purple.700}",
                        shadow: "none",
                        closeButton: {
                            hoverBackground: "{purple.300}",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem {purple.300}",
                            },
                        },
                        outlined: {
                            color: "{purple.600}",
                            borderColor: "{purple.600}",
                        },
                        simple: {
                            color: "{purple.600}",
                        },
                    },
                    // more saturated background colors
                    secondary: {
                        background: "color-mix(in srgb, {surface.200}, transparent 5%)",
                        closeButton: {
                            hoverBackground: "{surface.300}",
                            focusRing: {
                                shadow: "0 0 0 0.2rem {surface.300}",
                            },
                        },
                    },
                    success: {
                        background: "color-mix(in srgb, {green.200}, transparent 5%)",
                        color: "{green.700}",
                        closeButton: {
                            hoverBackground: "{green.300}",
                            focusRing: {
                                shadow: "0 0 0 0.2rem {green.300}",
                            },
                        },
                    },
                    info: {
                        background: "color-mix(in srgb, {blue.200}, transparent 5%)",
                        color: "{blue.700}",
                        closeButton: {
                            hoverBackground: "{blue.300}",
                            focusRing: {
                                shadow: "0 0 0 0.2rem {blue.300}",
                            },
                        },
                    },
                    warn: {
                        background: "color-mix(in srgb, {yellow.200}, transparent 5%)",
                        color: "{yellow.700}",
                        closeButton: {
                            hoverBackground: "{yellow.300}",
                            focusRing: {
                                shadow: "0 0 0 0.2rem {yellow.300}",
                            },
                        },
                    },
                    error: {
                        background: "color-mix(in srgb, {red.200}, transparent 5%)",
                        color: "{red.700}",
                        closeButton: {
                            hoverBackground: "{red.300}",
                            focusRing: {
                                shadow: "0 0 0 0.2rem {red.300}",
                            },
                        },
                    },
                    contrast: {
                        closeButton: {
                            // match the focus ring color
                            hoverBackground: "{surface.400}",
                        },
                    },
                },
                dark: {
                    // add primary messages
                    primary: {
                        background: "color-mix(in srgb, {purple.800}, transparent 74%)",
                        borderColor: "transparent",
                        color: "{purple.300}",
                        shadow: "none",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                color: "{focus.ring.color}",
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {purple.300}, transparent 80%)",
                            },
                        },
                        outlined: {
                            color: "{purple.500}",
                            borderColor: "{purple.500}",
                        },
                        simple: {
                            color: "{purple.500}",
                        },
                    },
                    secondary: {
                        background: "{surface.800}",
                        color: "{surface.300}",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {surface.300}, transparent 80%)",
                            },
                        },
                    },
                    info: {
                        background: "color-mix(in srgb, {blue.800}, transparent 74%)",
                        color: "{blue.300}",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {blue.300}, transparent 80%)",
                            },
                        },
                    },
                    success: {
                        background: "color-mix(in srgb, {green.800}, transparent 74%)",
                        color: "{green.300}",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {green.300}, transparent 80%)",
                            },
                        },
                    },
                    warn: {
                        background: "color-mix(in srgb, {yellow.800}, transparent 74%)",
                        color: "{yellow.300}",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {yellow.300}, transparent 80%)",
                            },
                        },
                    },
                    error: {
                        background: "color-mix(in srgb, {red.800}, transparent 74%)",
                        color: "{red.300}",
                        closeButton: {
                            hoverBackground: "rgba(255, 255, 255, 0.10)",
                            focusRing: {
                                shadow: "0 0 0 0.2rem color-mix(in srgb, {red.300}, transparent 80%)",
                            },
                        },
                    },
                },
            },
            css: ({ dt }) =>
                `
.p-message-primary {
    background: ${dt("message.primary.background")};
    outline-color: ${dt("message.primary.border.color")};
    color: ${dt("message.primary.color")};
    box-shadow: ${dt("message.primary.shadow")};
}

.p-message-primary.p-message-outlined {
    background: transparent;
    color: ${dt("message.primary.outlined.color")};
    outline-color: ${dt("message.primary.outlined.borderColor")};
}

.p-message-primary.p-message-simple {
    background: transparent;
    color: ${dt("message.primary.simple.color")};
}

.p-message-primary .p-message-close-button:hover {
    background: ${dt("message.primary.closeButton.hoverBackground")};
}

.p-message-primary .p-message-close-button:focus-visible {
    outline-color: ${dt("message.primary.closeButton.focusRing.color")};
    box-shadow: ${dt("message.primary.closeButton.focusRing.shadow")};
}
@media (min-width: 480px) {
    .p-message-outlined {
        outline-width: 2px;
    }
}
`,
        },
        select: {
            css: () =>
                `
.p-select-option {
min-width: max-content;
}
.p-select-overlay {
  width:40vw;
}
.p-warning {
border-color: var(--p-inputtext-warning-border-color);
}
`,
        },
    },
});
export default CustomizedLara;
